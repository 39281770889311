<template>
  <div class="notice" :style="style">
    <!-- 喇叭图片，可删除 -->
    <!-- <img class="icon-notice" src="./image/喇叭.png" /> -->
    <div class="marquee-wrap" @click="news">
      <!-- 为了计算总文本宽度，通过css在页面中隐藏 -->
      <p class="getWidth">{{text}}</p>
      <!-- 滚动内容 -->
      <div class="scroll">
        <p class="marquee">{{text}}</p>
        <!-- 文字副本 -->
        <p class="copy"></p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ["color", "background"],
  data() {
    return {
      timer: null,
      text: "",
      lists: [],
      style: "",
    };
  },
  created() {
    console.log(this.color, this.background);
    this.style = `color:${this.color};background:${this.background}`;
  },
  // 把父组件传入的arr转化成字符串
  mounted() {
    this.getNotice();
  },
  methods: {
    news() {
      this.$router.push(`/new?id=${this.id}`);
    },
    move() {
      let maxWidth = document.querySelector(".marquee-wrap").clientWidth;
      // 获取文字text 的计算后宽度 （由于overflow的存在，直接获取不到，需要独立的node计算）
      let width = document.querySelector(".getWidth").scrollWidth;
      // 如果文本内容的宽度小于页面宽度，则表示文字小于等于一行，则不需要滚动
      if (width <= maxWidth) return;
      let scroll = document.querySelector(".scroll");
      let copy = document.querySelector(".copy");
      copy.innerText = this.text; // 文字副本填充
      let distance = 0; // 位移距离
      // 设置位移
      this.timer = setInterval(() => {
        distance -= 1;
        // 如果位移超过文字宽度，则回到起点
        if (-distance >= width) {
          distance = 16; // 距离必须与marquee的margin宽度相同
        }
        scroll.style.transform = "translateX(" + distance + "px)";
      }, 20);
    },
    async getNotice() {
      const res = await this.axios("/api/index/notice");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        let num = data.title.length;
        console.log(num);
        // if (num < 155) {
        //   // 长度不够
        //   for (let i = 0; i < data.title.length; i++) {
        //     console.log(i);
        //     // console.log(data.title);
        //   }
        // }
        if (data.title.length < 6) {
          for (let i = 35; i > 0; i--) {
            this.lists.push(data.title);
          }
        } else {
          this.lists.push(data.title);
        }
        this.lists.push(data.title);

        this.id = data.id;
        // console.log(this.lists.length);

        let timer = setTimeout(() => {
          this.move();
          clearTimeout(timer);
        }, 500);
        for (let item of this.lists) {
          this.text += " " + item;
        }
      }
    },
  },
  beforeDestroy() {
    // 清除计时器
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.notice {
  // padding: 16/75rem 28/75rem;
  font-size: 0.27rem;
  color: #929292;
  line-height: 38/75rem;
  align-items: center;
  display: flex;
  height: 0.55rem;
  width: 100%;
  line-height: 0.55rem;
  bottom: 0;
  position: fixed;
  font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
  .icon-notice {
    width: 25/75rem;
    height: 21/75rem;
    margin-right: 14/75rem;
  }
  .marquee-wrap {
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    position: relative;
    .scroll {
      display: flex;
      z-index: 999;

      p {
        word-break: keep-all; // 不换行
        white-space: nowrap;
        /* 设置前后间隔 */
        &.marquee {
          margin-right: 16px;
        }
      }
    }
    /* 仅为了获取宽度，故隐藏掉 */
    .getWidth {
      word-break: keep-all; // 不换行
      white-space: nowrap;
      position: absolute;
      opacity: 0;
      top: 0;
    }
  }
}
@media (max-width: 400px) {
  .notice {
    font-size: 19px;
  }
}
</style>

