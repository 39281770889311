import { render, staticRenderFns } from "./horserace.vue?vue&type=template&id=13c81ccb&scoped=true&"
import script from "./horserace.vue?vue&type=script&lang=js&"
export * from "./horserace.vue?vue&type=script&lang=js&"
import style0 from "./horserace.vue?vue&type=style&index=0&id=13c81ccb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c81ccb",
  null
  
)

export default component.exports